var Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    SnapstackUrl = require('../config/snapstackUrl'),
    SidebarMenuGeneralView = require('./sidebarMenuGeneral'),
    template = require('../../dist/templates').sidebar;

var SidebarView = Marionette.View.extend({
    template: template,
    id: "sidebar",

    regions: {
        mainMenuContainer: '#mainmenu-sidebar',
    },

    ui: {
        logoutBtn: '#logout-button',
    },

    events: {
        'click @ui.logoutBtn': 'handleClickLogout',
    },

    initialize: function () {

    },

    onRender: function () {
        this.showChildView('mainMenuContainer', new SidebarMenuGeneralView());
    },

    handleClickLogout: function () {
        Snapstack.vent.trigger('api:loadingStarted');
        
        Backbone.$.ajax({
            url: SnapstackUrl + 'users/logout',
            type: 'GET',
            crossDomain: true,
            headers: {
                /*
                 * Set the 'Authorization' header and get the access
                 * token from the `auth` module
                 */
                'Authorization': 'Bearer ' + localStorage.token,
            },
            success: function () {
                Snapstack.vent.trigger('api:loadingFinished');
                localStorage.removeItem('token');
                location.reload();
            },
            error: function (xhr, ajaxOptions, thrownError) {
                Snapstack.vent.trigger('api:loadingFinished');
                console.log(xhr.status);
                console.log(thrownError);
            },
        });
    },
});

module.exports = SidebarView;
