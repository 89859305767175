JOHNSNITH_TITLES = []

JOHNSNITH_TITLES.push("John Smith is a way too common name");
JOHNSNITH_TITLES.push("John Smith is the man");
JOHNSNITH_TITLES.push("John Smith is a real person");
JOHNSNITH_TITLES.push("John Smith isn’t up there?");
JOHNSNITH_TITLES.push("John Smith is this");
JOHNSNITH_TITLES.push("John Smith is an often repeated myth");
JOHNSNITH_TITLES.push("John Smith is different");
JOHNSNITH_TITLES.push("John Smith isn’t a query that makes sense to humans");
JOHNSNITH_TITLES.push("John Smith is a good rapper");
JOHNSNITH_TITLES.push("John Smith is a peculiarity of scammers");
JOHNSNITH_TITLES.push("John Smith isn’t all that common here in Sweden");
JOHNSNITH_TITLES.push("John Smith is sick all his shit is dope especially this track");
JOHNSNITH_TITLES.push("John Smith is my uncle and it’s in the Holy Bible");
JOHNSNITH_TITLES.push("John Smith is a decorated firefighter");
JOHNSNITH_TITLES.push("John Smith is kind of a big deal");
JOHNSNITH_TITLES.push('John Smith is driving a tractor');
JOHNSNITH_TITLES.push('John Smith is expired');
JOHNSNITH_TITLES.push('John Smith is employee number 2351');
JOHNSNITH_TITLES.push('John Smith is selling drugs at the corner');
JOHNSNITH_TITLES.push('John Smith is still allowed to sign for hisself');
JOHNSNITH_TITLES.push('John Smith is designed to provide world-class comfort and support');
JOHNSNITH_TITLES.push('John Smith is a moron');
JOHNSNITH_TITLES.push('John Smith is mentioned most often in local land transactions');
JOHNSNITH_TITLES.push('John Smith is our in-house expert');
JOHNSNITH_TITLES.push('John Smith is the best endodontist in town');
JOHNSNITH_TITLES.push('John Smith is writing a message');
JOHNSNITH_TITLES.push('John Smith is married to Rebecca Smith');
JOHNSNITH_TITLES.push('John Smith is a liar');
JOHNSNITH_TITLES.push('John Smith is often his own favorite hero');
JOHNSNITH_TITLES.push('John Smith is the Customer');
JOHNSNITH_TITLES.push('John Smith is their active CEO');
JOHNSNITH_TITLES.push('John Smith is planning to visit Jane Doe in Cincinnati');
JOHNSNITH_TITLES.push('John Smith is the father of child A');
JOHNSNITH_TITLES.push('John Smith is still moving crabwise towards modernity');
JOHNSNITH_TITLES.push('John Smith is a champagne socialist');
JOHNSNITH_TITLES.push('John Smith is now “officially” Lord John Smith of Mayfair');
JOHNSNITH_TITLES.push('John Smith is about to be killed');
JOHNSNITH_TITLES.push('John Smith is a Tai Chi teacher');
JOHNSNITH_TITLES.push('John Smith is now a member of Watch Dear John Online Free');
JOHNSNITH_TITLES.push('John Smith is from Wales');
JOHNSNITH_TITLES.push('John Smith is a legendary story');
JOHNSNITH_TITLES.push('John Smith is always a great word to know');
JOHNSNITH_TITLES.push('John Smith is a name synonymous with wrestling success');
JOHNSNITH_TITLES.push('John Smith is a freak');
JOHNSNITH_TITLES.push('John Smith is a comic book creator');
JOHNSNITH_TITLES.push('John Smith is a 28 years-old M.B.A. graduate');
JOHNSNITH_TITLES.push('John Smith is a 22-year-old writer');
JOHNSNITH_TITLES.push("John Smith is the prototype");
JOHNSNITH_TITLES.push("John Smith is a murderer and his fate lies in your hands");
JOHNSNITH_TITLES.push("John Smith is the first modern day Scottish crier");
JOHNSNITH_TITLES.push("John Smith is accused of plotting a mutiny");
JOHNSNITH_TITLES.push("John Smith is available 100% of the time");
JOHNSNITH_TITLES.push("John Smith is made to look foolish");
JOHNSNITH_TITLES.push("John Smith is 11 weeks old");
JOHNSNITH_TITLES.push("John Smith is the only eyewitness");
JOHNSNITH_TITLES.push("John Smith is an amoral gunslinger in the days of Prohibition");
JOHNSNITH_TITLES.push("John Smith is a name Kyon made up");
JOHNSNITH_TITLES.push("John Smith is eating");
JOHNSNITH_TITLES.push("John Smith is on youtube.com");
JOHNSNITH_TITLES.push("John Smith is just so average that you’re forgettable");
JOHNSNITH_TITLES.push("John Smith is a 34 year old entrepreneur");
JOHNSNITH_TITLES.push("John Smith is for you");
JOHNSNITH_TITLES.push("John Smith is one of the best dentists I’ve ever had");
JOHNSNITH_TITLES.push("John Smith is the host of the Conspire segment on WKTT");
JOHNSNITH_TITLES.push("John Smith is in trouble a lot");
JOHNSNITH_TITLES.push("John Smith is wealthy, daring, athletic, and smart");
JOHNSNITH_TITLES.push("John Smith is only miles away");
JOHNSNITH_TITLES.push("John Smith is wrong, johnsmith is correct");
JOHNSNITH_TITLES.push("John Smith is an android created by Red Tornado");
JOHNSNITH_TITLES.push("John Smith is on fire!");
JOHNSNITH_TITLES.push("John Smith is shocked and ashamed");
JOHNSNITH_TITLES.push("John Smith is still here?");
JOHNSNITH_TITLES.push("John Smith is a jerk or not!");
JOHNSNITH_TITLES.push("John Smith is hanged for treason");
JOHNSNITH_TITLES.push("John Smith is immediately highlighted");
JOHNSNITH_TITLES.push("John Smith is thinking");
JOHNSNITH_TITLES.push("John Smith is profoundly Deaf");
JOHNSNITH_TITLES.push("John Smith is idle");
JOHNSNITH_TITLES.push("John Smith (John Smith!) is Pittacus Lore");
JOHNSNITH_TITLES.push("John Smith is 54 years old");
JOHNSNITH_TITLES.push("John Smith is going on public display");
JOHNSNITH_TITLES.push("John Smith is impressed");
JOHNSNITH_TITLES.push("John Smith is in a bad car accident");
JOHNSNITH_TITLES.push("John Smith is their builder and mason");
JOHNSNITH_TITLES.push("John Smith is a wood turner");
JOHNSNITH_TITLES.push("John Smith is likely apocryphal");
JOHNSNITH_TITLES.push("John Smith is no ordinary teen");
JOHNSNITH_TITLES.push("John Smith is a Time Lord");
JOHNSNITH_TITLES.push("John Smith isn’t available in any dictionary");
JOHNSNITH_TITLES.push("John Smith is like me right now");
JOHNSNITH_TITLES.push("John Smith is the hardest to grasp");
JOHNSNITH_TITLES.push("John Smith is the proper Elvis");
JOHNSNITH_TITLES.push("John Smith is a visionary among plumbers");
JOHNSNITH_TITLES.push("John Smith is only in parades");
JOHNSNITH_TITLES.push("John Smith is a carrot");
JOHNSNITH_TITLES.push("John Smith isn’t spelled anything like Billy Bones");
JOHNSNITH_TITLES.push("John Smith is it")
JOHNSNITH_TITLES.push("John Smith is dead, and you look like him")
JOHNSNITH_TITLES.push("John Smith is telling you that")
JOHNSNITH_TITLES.push("JOHN SMITH IS NOT JOHN SMITH")
JOHNSNITH_TITLES.push("John Smith is a person; all people need to breathe oxygen")
JOHNSNITH_TITLES.push("John Smith is a manager, then we can deduce that John Smith is a human being")
JOHNSNITH_TITLES.push("John Smith isn’t his real name, it’s just one of many he’s had since he arrived on the planet Earth")
JOHNSNITH_TITLES.push("John Smith is superbly trained and well experienced in all aspects of cosmetic plastic surgery.")
JOHNSNITH_TITLES.push("John Smith isn’t really a writer, just thought I should let you know that")
JOHNSNITH_TITLES.push('John Smith is somewhat of a staple and, in many bars, is listed as “extra cold”')
JOHNSNITH_TITLES.push('John Smith is unemployed, and finds a job, then at least John Smith has progressed')
JOHNSNITH_TITLES.push('John Smith is the fourth person/ eld option, and is selected by the user')
JOHNSNITH_TITLES.push('John Smith is an ophthalmologist and Joe Smith is an optometrist')
JOHNSNITH_TITLES.push('John Smith is an employee at the Versalife Data Entry Facility in Hong Kong')

module.exports = JOHNSNITH_TITLES;