var Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    Snapstack = require('./snapstack');

var Snapstack = new Snapstack();

// Overriding Backbone default sync to add credentials
var proxiedSync = Backbone.sync;

Backbone.sync = function (method, model, options) {
    options || (options = {});

    if (!options.crossDomain) {
        options.crossDomain = true;
    }

    options.headers = {
        /*
         * Set the 'Authorization' header and get the access
         * token from the `auth` module
         */
        'Authorization': 'Bearer ' + localStorage.token,
    };


    return proxiedSync(method, model, options);
};

// Using on start ensure that history is started only after router and
// controllers are set
Snapstack.on('start', function () {
    if (Backbone.history) {
        Backbone.history.start();
    }
});

Snapstack.start();

var UID = {
    _current: 0,
    getNew: function(){
        this._current++;
        return this._current;
    }
};

HTMLElement.prototype.pseudoStyle = function(element,prop,value){
    var _this = this;
    var _sheetId = "pseudoStyles";
    var _head = document.head || document.getElementsByTagName('head')[0];
    var _sheet = document.getElementById(_sheetId) || document.createElement('style');
    _sheet.id = _sheetId;
    var className = "pseudoStyle" + UID.getNew();
    
    _this.className +=  " "+className; 
    
    _sheet.innerHTML += " ."+className+":"+element+"{"+prop+":"+value+"}";
    _head.appendChild(_sheet);
    return this;
};