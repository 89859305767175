var Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    SnapstackUrl = require('../config/snapstackUrl'),
    ContactModalView = require('./contactModal'),
    template = require('../../dist/templates').navbar;

var NavbarView = Marionette.View.extend({
    template: template,

    ui: {
        btnDonjon: '#btn-donjon',
        btnContact: '#btn-contact'
    },

    events: {
        'click @ui.btnDonjon': 'handleClickDonjon',
        'click @ui.btnContact': 'handleClickContact'
    },

    handleClickDonjon: function() {
        this.scrollToHoriz(500, 'right-window');
    },

    handleClickContact: function() {
        var options = {

            confirmLabel: "Save",
            // model: projectMod,
            // confirmCallback: _.bind(this.chargeCard, this), //Function to sub
            optional: true
        };
        //If user has a subscription, create a new subscription model
        

        Snapstack.showChildView('modalContainer', new ContactModalView(options));
    },

    scrollToHoriz: function(scrollDuration, id) {
        var elPos = this.getPosition(document.getElementById(id));
        var pagePos = { x: document.documentElement.scrollLeft, y : document.documentElement.scrollTop };
        var directions = { x : elPos.x > pagePos.x, y : elPos.y > pagePos.y};

        // console.log(elPos);
        var xParameter = (Math.abs(elPos.x) / 2),
            yParameter = (Math.abs(elPos.y) / 2),

            scrollCount = 0,
            oldTimestamp = performance.now(),
            firstTimestamp = oldTimestamp;
        function step (newTimestamp) {
            scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
            // console.log(scrollCount)
            if (scrollCount >= Math.PI) window.scrollTo(elPos.x, elPos.y);

            var newScrolls = { x : 0, y : 0};

            if (directions.x) {
                newScrolls.x = xParameter - xParameter * Math.cos(scrollCount);
            } else {
                newScrolls.x = xParameter + xParameter * Math.cos(scrollCount);
            }

            if (directions.y) {
                newScrolls.y = yParameter - yParameter * Math.cos(scrollCount);
            } else {
                newScrolls.y = yParameter + yParameter * Math.cos(scrollCount);
            }

            window.scrollTo(Math.round(newScrolls.x), 0);
            oldTimestamp = newTimestamp;

            if(newTimestamp >= (firstTimestamp + scrollDuration)){
                if (directions.x) {
                    newScrolls.x = xParameter - xParameter * Math.cos(Math.PI);
                } else {
                    newScrolls.x = xParameter + xParameter * Math.cos(Math.PI);
                }

                if (directions.y) {
                    newScrolls.y = yParameter - yParameter * Math.cos(Math.PI);
                } else {
                    newScrolls.y = yParameter + yParameter * Math.cos(Math.PI);
                }

                window.scrollTo(newScrolls.x, 0);
                return;
            }
            window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
    },

    getPosition: function(el) {
        var xPos = 0;
        var yPos = 0;

        while (el) {
            if (el.tagName == "BODY") {
                // deal with browser quirks with body/window/document and page scroll
                var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
                var yScroll = el.scrollTop || document.documentElement.scrollTop;

                xPos += (el.offsetLeft - xScroll + el.clientLeft);
                yPos += (el.offsetTop - yScroll + el.clientTop);
            } else {
                // for all other non-BODY elements
                xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
                yPos += (el.offsetTop - el.scrollTop + el.clientTop);
            }

            el = el.offsetParent;
        }
        return {
            x: xPos,
            y: yPos
        };
    },
});

module.exports = NavbarView;
