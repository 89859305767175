var Marionette = require('backbone.marionette');

var FadeInRegion = Marionette.Region.extend({
  attachHtml: function(view){
    // Some effect to show the view:
    this.$el.empty().append(view.el);
    this.$el.hide().fadeIn(350);
  }
});

module.exports = FadeInRegion;