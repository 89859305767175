var Backbone = require("backbone"),
    SnapstackUrl = require("../config/snapstackUrl");

var ProjectModel = Backbone.Model.extend({
	defaults: {
		id: 1,
		name: ' ',
		imageUrl: 'assets/img/projects/1.jpg',
		description: ' '
	},
    idAttribute: "id",
    urlRoot: SnapstackUrl + "assets/data/projects.json",

    initialize: function() {
    	// this.url = SnapstackUrl + 'users/' + this.get('username')
    },  
});

module.exports = ProjectModel;