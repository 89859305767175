JOHNSNITH_TAGLINES = []
JOHNSNITH_TAGLINES.push("JOHN SMITH IS AVAILABLE NOW");
JOHNSNITH_TAGLINES.push("JOHN SMITH NEEDS YOU SO MUCH");
JOHNSNITH_TAGLINES.push("JOHN SMITH IS SEXY AS HELL");
JOHNSNITH_TAGLINES.push("JOHN SMITH IS BALD AND STRONG");
JOHNSNITH_TAGLINES.push("VOTE FOR JOHN SMITH");
JOHNSNITH_TAGLINES.push("BUY JOHN SMITH NOW");
JOHNSNITH_TAGLINES.push("JOHN SMITH CAN BE BOUGHT");
JOHNSNITH_TAGLINES.push("JOHN SMITH CAN BE YOURS");
JOHNSNITH_TAGLINES.push("YOU GOT TO GET JOHN SMITH");
JOHNSNITH_TAGLINES.push("GET A JOHN SMITH OF YOUR OWN");
JOHNSNITH_TAGLINES.push("BUY YOUR OWN COPY OF JOHN SMITH");
JOHNSNITH_TAGLINES.push("JOHN SMITH IS PERSONALIZED");
JOHNSNITH_TAGLINES.push("JOHN SMITH IS YOURS FOR $24.99");
JOHNSNITH_TAGLINES.push("JOHN SMITH IS ONLY $24.99");
JOHNSNITH_TAGLINES.push("JOHN SMITH IS $24.99");
JOHNSNITH_TAGLINES.push("THE QUANTITIES ARE UNLIMITED");
JOHNSNITH_TAGLINES.push("ORDER QUANTITY IS UNLIMITED");

module.exports = JOHNSNITH_TAGLINES;