var Backbone = require("backbone"),
    SnapstackUrl = require("../config/snapstackUrl");

var UserModel = Backbone.Model.extend({
    idAttribute: "username",
    urlRoot: SnapstackUrl + "users",

    initialize: function() {
    	// this.url = SnapstackUrl + 'users/' + this.get('username')
    },  
});

module.exports = UserModel;