var Marionette = require('backbone.marionette'),
    template = require('../../dist/templates').app,
    //Regions
    FadeInRegion = require('../regions/fadeIn'),
    FadeInOutRegion = require('../regions/fadeInOut'),
    //Views
    NavbarView = require('../views/navbar'),
    SidebarView = require('../views/sidebar'),
    RegisterView = require('../views/register'),
    HomeView = require('../views/home'),
    AppView = require('../views/app');

var AppView = Marionette.View.extend({
    template: template,
    regions: {
        mainContainer: {
        	el: '#main-container',
        	regionClass: FadeInRegion
        },
        alertContainer: {
            el: '#alert-container',
            regionClass: FadeInOutRegion   
        },
        modalContainer: '#modal-container',
        navbarContainer: '#navbar-container',
        sidebarContainer: '#sidebar-container'
    },

    initialize: function() {

    },

    onRender: function() {
	},

    onShow: function() {
    }
});

module.exports = AppView;
