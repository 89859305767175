var _ = require('underscore'),
    Marionette = require('backbone.marionette'),
	UserModel = require('../models/user'),
    ConfirmModalView = require("./confirmModal"),
    template = require('../../dist/templates').settings;

var SettingsView = Marionette.View.extend({
    template: template,

    ui: {
        submitBtn: '#btn-save'
    },

    events: {
        'click @ui.submitBtn': 'handleClickSave'
    },

    initialize: function() {
        this.model.on('request', function(){
            Snapstack.vent.trigger('api:loadingStarted');
        });
        this.model.on('sync', function(model, response, options){
            Snapstack.vent.trigger('api:loadingFinished');

            var options = {
                type:'success',
                title: 'Success!',
                message: 'Profile updated!'
            };
            
            Snapstack.vent.trigger('app:showAlert', options);
            Snapstack.vent.trigger('user:Refresh', response);
        });
        this.model.on('error', function(model, response, options){
            Snapstack.vent.trigger('api:loadingFinished');

            var options = {
                type:'danger',
                title: 'Error!',
                message: response.responseJSON.message
            };

            Snapstack.vent.trigger('app:showAlert', options);
        });
    },

    handleClickSave: function(e) {
        e.preventDefault();

        var data = {
            username: this.model.get('username'),
            snapchat_name: $("#snapchat-name").val(),
            password: $("#password").val(),
            firstname: $("#firstname").val(),
            lastname: $("#lastname").val(),
            email: $("#email").val(),
            subscription_price: $("#subscription-price").val(),
            subscription_enabled: document.getElementById('subscription-enabled').checked
        };

        this.model.set(data);
        this.model.save();
    }

    // handleClickRegister: function(e) {
    // 	e.preventDefault();

    // 	var data = {
    //         username: $("#username").val(),
    //         snapchat_name: $("#snapchat-name").val(),
    //         password: $("#password").val(),
    //         email: $("#email").val(),
    //         birthday: $("#birthday").val(),
    //         account_type: $("#account-type").val()
    //     };

    //     // If we're adding a new game, its collection isn't defined,
    //     // so we add it.
    //     // if(!this.model.collection) {
    //     //     this.collection.add(this.model);
    //     // }

    //     this.model.set(data);
    //     this.model.save();
    // }
});

module.exports = SettingsView;
