var Backbone = require('backbone'),
	Marionette = require('backbone.marionette'),
    template = require('../../dist/templates').alert;

var AlertView = Marionette.View.extend({
    template: template,
    templateContext: function () {
        return {
        	type: this.type,
            title: this.title,
            message: this.message
        };
    },

    ui: {
        closeBtn: '#btn-close-alert'
    },

    events: {
        'click @ui.closeBtn': 'handleClickClose'
    },

    initialize: function(options) {
        this.type = options.type || 'warning';
        this.title = options.title || '';
        this.message = options.message || '';
    },

    onRender: function() {
        $('#alert-container').css('z-index', '10000');
    	//Auto fade-out after 2 seconds
    	this.closePromise = setTimeout(function() {
            Snapstack.getView().getRegion('alertContainer').empty();
        }, 4000);
    },

    handleClickClose: function(e) {
    	e.preventDefault();

        Snapstack.getView().getRegion('alertContainer').empty();

    	clearTimeout(this.closePromise);
    },

    onDestroy: function() {
        $('#alert-container').css('z-index', '0');
    }
});

module.exports = AlertView;
