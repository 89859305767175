var Marionette = require('backbone.marionette'),
    template = require('../../dist/templates').message;

var MessageView = Marionette.View.extend({
    template: template,
    templateContext: function () {
        return {
            title: this.options.title,
            message: this.options.message
        };
    },

    initialize: function(options) {
    }
});

module.exports = MessageView;
