var Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    // Snapstack = require('../snapstack.js'),
    SnapstackUrl = require('../config/snapstackUrl'),
    // Collections
    // SectionsCollection = require('../collections/sections'),
    // ProjectsCollection = require('../collections/projects'),
    // ProjectUsersCollection = require('../collections/projectUsers'),
    // RightsCollection = require('../collections/rights'),
    // Models
    UserModel = require('../models/user'),
    // Views
    NavbarView = require('../views/navbar'),
    SidebarView = require('../views/sidebar'),
    RegisterView = require('../views/register'),
    SettingsView = require('../views/settings'),
    MessageView = require('../views/message'),
    UserView = require('../views/user'),
    HomeView = require('../views/home'),
    AlertView = require('../views/alert'),
    AppView = require('../views/app');

var SnapstackController = Marionette.Object.extend({
    initialize: function () {
        Snapstack.showView(new AppView());

        this.listenTo(Snapstack.vent, 'app:showAlert',
                this.showAlert);
    },

    handleUserLoggedOut: function () {
        Snapstack.isLoggedIn = false;
    },

    start: function () {
        // alert('')
        this.showNavbar();     
    },

    showNavbar: function () {
        Snapstack.showChildView('navbarContainer', new NavbarView());
    },

    showAlert: function(options) {
        Snapstack.showChildView('alertContainer', new AlertView(options));
    },

    showHomePage: function () {
        // alert('home')
        Snapstack.showChildView('mainContainer', new HomeView());
    },

    handleLoadingStarted: function() {
        $("#loader").append('<div class="spinner"></div>');
        $("#loader").css('z-index', '1');
        $("#loader").css('background', 'rgba(0, 0, 0, 0.25)');
        $(".spinner").css('background', 'rgba(0, 0, 0, 0.5)');
    },

    handleLoadingFinished: function() {
        setTimeout(function() {
            $("#loader").css('z-index', '-1000');
            $("#loader").html('');
        }, 1000);

        $("#loader").css('background', 'rgba(0, 0, 0, 0.0)');
        $(".spinner").css('background', 'rgba(0, 0, 0, 0.0)');
    }
});

module.exports = SnapstackController;
