var Backbone = require('backbone'),
    _ = require('underscore'),
    Marionette = require('backbone.marionette'),
    // Controllers
    SnapstackController = require('./controllers/snapstackController'),
    // Routers
    SnapstackRouter = require('./router/snapstackRouter');

var snapstack = Marionette.Application.extend({
    region: '#app-container',

    onBeforeStart: function() {
        window.Snapstack = this;

        // window.stripe = Stripe('pk_test_jsuuNJmw8EgTOAq0ZS6hq1wK');

        //Initializing Events
        var vent = _.extend({}, Backbone.Events);

        Snapstack.vent = vent;

        //Initializing Controller
        var snapstackController = new SnapstackController();
        Snapstack.snapstackController = snapstackController;
        Snapstack.snapstackController.start();

        //Initializing Router
        var snapstackRouter = new SnapstackRouter({
            controller: Snapstack.snapstackController,
        });

        Snapstack.snapstackRouter = snapstackRouter;
    },
    
    showChildView: function(regionName, view) {
      this.getView().showChildView(regionName, view);
    }
});

module.exports = snapstack;
