var Backbone = require("backbone"),
    bbm = require("backbone.modal"),
    // slick = require('slick-carousel'),
    ProjectModel = require("../models/project"),
    SnapstackUrl = require('../config/snapstackUrl'),
    // UserModel = require('../models/user'),
    template = require("../../dist/templates").contactModal;

var ContactModalView = Backbone.Modal.extend({
    template: template,
    id: 'contact-modal',
    cancelEl: ".dismiss",
    submitEl: "#btn-send",

    initialize: function(options) {
        // this.model = options.model || new ProjectModel();

        this.confirmLabel = options.confirmLabel;
        this.confirmCallback = options.confirmCallback;
        this.optional = options.optional;

        // console.log(this.model);

        this.validateRules = [
            {
                name: "nom",
                type: "not-empty",
                message: "Nom Vide"
            },
            {
                name: "email",
                type: "email",
                message: "Courriel Invalide"
            },
            {
                name: "message",
                type: "not-empty",
                message: "Message Vide"
            }
        ];
    },

    onShow: function() {
        //Add glass effect to background content
        $("#navbar-container").addClass('blur-effect');
        $("#content-container").addClass('blur-effect');

        if(!this.optional)
            $('.dismiss').remove();
    },

    beforeSubmit: function() {
        // this.close();
        if(!this.validate())
            return false;

        var formData = {
            'email': $("#email").val(),
            'subject': "New Ottomata Message",
            'message': "Name : " + $("#nom").val() + "<br /><br />" + $("#message").val()
        };
        // console.log(Backbone.$.param(formData));
        var view = this;
        Backbone.$.ajax({
            url: SnapstackUrl + "contactme.php",
            type: 'POST',
            data: Backbone.$.param(formData),
            crossDomain: true,
            success : function(data){
                // OOS.vent.trigger("message:Sent")
                if(typeof data !== 'undefined') {
                    data = JSON.parse(data);
                    if(data.status == "success") {
                        var options = {
                            type:'success',
                            title: '',
                            message: 'Le message a bien été envoyé!'
                        };
                        Snapstack.vent.trigger('app:showAlert', options);
                        // $("#message-handler").html("")
                        // $("#message-handler").html("<p>Le message a bien été envoyé</p>")
                        // var view = this;
                        // setTimeout(function() {
                        //     view.submit();
                        //     $("#message-handler").html("");
                        // }, 2500);
                    } else {
                        var options = {
                            type:'danger',
                            title: '',
                            message: "Une erreur s'est produite!"
                        };
                        Snapstack.vent.trigger('app:showAlert', options);
                        // $("#message-handler").html("")
                        // $("#message-handler").html("<p>Une erreur s'est produite</p>")
                        // var view = this;
                        // setTimeout(function() {
                        //     view.handleClickOrder();
                        //     $("#message-handler").html("");
                        // }, 2500);
                    }
                } else {
                    var options = {
                        type:'danger',
                        title: '',
                        message: "Une erreur s'est produite!"
                    };
                    Snapstack.vent.trigger('app:showAlert', options);
                    // $("#message-handler").html("")
                    // $("#message-handler").html("<p>Une erreur s'est produite</p>")
                    // var view = this;
                    // setTimeout(function() {
                    //     view.handleClickOrder();
                    //     $("#message-handler").html("");
                    // }, 2500);
                }
            },
            error : function (xhr, ajaxOptions, thrownError){
                var options = {
                    type:'danger',
                    title: '',
                    message: "Une erreur s'est produite!"
                };
                Snapstack.vent.trigger('app:showAlert', options);
                // $("#message-handler").html("")
                // $("#message-handler").html("<p>Une erreur s'est produite</p>")
                // var view = this;
                // setTimeout(function() {
                //     view.handleClickOrder();
                //     $("#message-handler").html("");
                // }, 2500);
                console.log(xhr);
                console.log(thrownError);
            }
        });
        // return false;
    },

    submit: function() {

        //Remove glass effect to background content
        $("#navbar-container").removeClass('blur-effect');
        $("#content-container").removeClass('blur-effect');
    },

    cancel: function() {
        //Remove glass effect to background content
        $("#navbar-container").removeClass('blur-effect');
        $("#content-container").removeClass('blur-effect');
    },

    validate: function() {
        //If no validate rules, return
        if(typeof this.validateRules === 'undefined')
            return true;

        //Global variable to check if validation has passed
        var validationPassed = true;

        //First we cleanup the old errors if there are some
        var oldErrors = document.getElementsByClassName("validation-error");
        while (oldErrors[0]) {
            oldErrors[0].parentNode.removeChild(oldErrors[0]);
        }

        //Then we test the validation rules
        for(var i in this.validateRules) {
            var isValid = true;

            if(typeof this.validateRules[i].name !== 'undefined')
                var currentEl = document.getElementsByName(this.validateRules[i].name)[0];
            else if(typeof this.validateRules[i].class !== 'undefined')
                var currentEl = document.getElementsByClassName(this.validateRules[i].class);
            else
                continue;

            var parentEl = document.getElementById(this.validateRules[i].parent) || currentEl.parentElement;
            var currentVal = currentEl.value;
            var messagePosition = this.validateRules[i].messagePosition || "after";

            var error = document.createElement("div");
            error.innerHTML = '<span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span><span class="sr-only">Error:</span> ' + this.validateRules[i].message;
            error.setAttribute('role', 'alert');
            error.classList.add("validation-error");

            parentEl.classList.remove("has-error");
            parentEl.classList.remove("has-feedback");

            switch(this.validateRules[i].type) {
                case "not-empty":
                    isValid = (currentVal.length > 0);
                break;
                case "email":
                    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                    isValid = re.test(currentVal);
                break;
                case "count":
                    if(typeof this.validateRules[i].min !== 'undefined')
                        isValid = (currentEl.length >= this.validateRules[i].min);
                    if(typeof this.validateRules[i].max !== 'undefined')
                        isValid = (currentEl.length <= this.validateRules[i].max);
                break;
                case "different":
                    var verifiedVars = [];
                    for(var j = 0; j < currentEl.length; j++) {
                        var itemValue = currentEl[j].innerHTML;
                        if(verifiedVars.indexOf(itemValue) == -1)
                            verifiedVars.push(currentEl[j].innerHTML);
                        else {
                            isValid = false;
                            break;
                        }
                    }
                break;
                case "ajax":
                    // isValid = (currentVal.length > 5);
                    // console.log("ajax validate");
                    Backbone.$.ajax({
                        url: this.validateRules[i].url,
                        type: this.validateRules[i].ajaxType || 'POST',
                        data: currentVal,
                        crossDomain: true,
                        success : function(data){
                            console.log(data);
                        },
                        error : function (xhr, ajaxOptions, thrownError){
                            console.log(xhr.status);
                            console.log(thrownError);
                        }
                    });
                    isValid = false;
                break;
                default:
                    isValid = true;
            }

            if(!isValid){
                validationPassed = false;
                parentEl.classList.add("has-error");
                //parentEl.classList.add("has-feedback");
                // if(messagePosition == "before")
                //     parentEl.parentElement.insertBefore(error, parentEl);
                // else
                //     parentEl.appendChild(error);
                //return false; //TODO: Rewrite this, you should return false before testing all vals in the array and then show errors to user
            }
        }
        return validationPassed;
    }
});

module.exports = ContactModalView;