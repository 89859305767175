var _ = require('underscore'),
    Backbone = require('backbone'),
    SnapstackUrl = require('../config/snapstackUrl'),
    Marionette = require('backbone.marionette'),
    ConfirmModalView = require("./confirmModal"),
    StripeModalView = require("./stripeModal"),
    template = require('../../dist/templates').user;

var UserView = Marionette.View.extend({
    template: template,
    templateContext: function () {
        return {
            isMyAccount: Snapstack.isLoggedIn ? (Snapstack.userInfo.username == this.model.get('username')) : true
        };
    },

    ui: {
        subBtn: '#btn-subscribe'
    },

    events: {
        'click @ui.subBtn': 'handleClickSubscribe'
    },

    initialize: function () {
        this.model.on('change', this.render, this);
    },

    onRender: function () {
    },

    handleClickSubscribe: function() {
        var options = {
            message: 'You are about to subscribe to <b>' + this.model.get('username') + '</b> for <b>' + this.model.get('subscription_price') + '$/month</b>. Do you want to continue?',
            confirmLabel: "Continue",
            confirmCallback: _.bind(this.verifyCardSettings, this),
            optional: true
        };

        Snapstack.showChildView('modalContainer', new ConfirmModalView(options));
    },

    verifyCardSettings: function() {
        var userView = this;
        //Check if card is present
        Backbone.$.ajax({
            url: SnapstackUrl + 'users/me/payment-informations',
            type: 'GET',
            crossDomain: true,
            headers: {
                /*
                 * Set the 'Authorization' header and get the access
                 * token from the `auth` module
                 */
                'Authorization': 'Bearer ' + localStorage.token,
            },
            success: function (data) {
                if(data.credit_card_linked) {
                    userView.chargeCard();
                } else {
                    userView.addNewCreditCard();
                }
                // var user = new UserModel(data);
                // Snapstack.showChildView('mainContainer', new SettingsView({
                //     model: user
                // }));
            },
        });
        //Code to start subscription with stripe

        //Else code to add new card
    },

    addNewCreditCard: function() {
        // alert("do stuff to subscribe");
        // Snapstack.vent.trigger('api:loadingStarted');

        //Verify if user has a subscription
        var options = {

            confirmLabel: "Save",
            confirmCallback: _.bind(this.chargeCard, this), //Function to sub
            optional: true
        };
        //If user has a subscription, create a new subscription model
        

        Snapstack.showChildView('modalContainer', new StripeModalView(options));
    },

    chargeCard: function() {
        alert("charging card");
    }
});

module.exports = UserView;