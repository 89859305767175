var _ = require('underscore'),
    Marionette = require('backbone.marionette'),
	UserModel = require('../models/user'),
    ConfirmModalView = require("./confirmModal"),
    template = require('../../dist/templates').register;

var RegisterView = Marionette.View.extend({
    template: template,

    ui: {
        submitBtn: '#btn-register'
    },

    events: {
        'click @ui.submitBtn': 'handleClickRegister'
    },

    initialize: function() {
    	this.model = new UserModel();
        this.collection = this.model.collection;

        this.model.on('request', function(){
            Snapstack.vent.trigger('api:loadingStarted');
        });
        this.model.on('sync', function(model, response, options){
            Snapstack.vent.trigger('api:loadingFinished');
            Snapstack.vent.trigger('user:newRegistration', model);
        });
        this.model.on('error', function(model, response, options){
            Snapstack.vent.trigger('api:loadingFinished');

            var options = {
                message: response.responseJSON.message,
                confirmLabel: "Continue",
                confirmCallback: _.bind(function(){}, this),
                optional: false
            };

            Snapstack.showChildView('modalContainer', new ConfirmModalView(options));
        });
    },

    handleClickRegister: function(e) {
    	e.preventDefault();

    	var data = {
            username: $("#username").val(),
            snapchat_name: $("#snapchat-name").val(),
            password: $("#password").val(),
            email: $("#email").val(),
            birthday: $("#birthday").val(),
            account_type: $("#account-type").val()
        };

        // If we're adding a new game, its collection isn't defined,
        // so we add it.
        // if(!this.model.collection) {
        //     this.collection.add(this.model);
        // }

        this.model.set(data);
        this.model.save();
    }
});

module.exports = RegisterView;
