var Backbone = require("backbone"),
    bbm = require("backbone.modal"),
    UserModel = require('../models/user'),
    template = require("../../dist/templates").stripeModal;

var StripeModalView = Backbone.Modal.extend({
    template: template,
    cancelEl: ".dismiss",
    submitEl: "#confirm-btn",

    initialize: function(options) {
        this.confirmLabel = options.confirmLabel;
        this.confirmCallback = options.confirmCallback;
        this.optional = options.optional;
    },

    onShow: function() {
        if(!this.optional)
            $('.dismiss').remove();

        this.elements = stripe.elements();

        // Custom styling can be passed to options when creating an Element.
        var style = {
          base: {
            color: '#303238',
            fontSize: '16px',
            lineHeight: '48px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: '#ccc',
            },
          },
          invalid: {
            color: '#e5424d',
            ':focus': {
              color: '#303238',
            },
          },
        };

        // Create an instance of the card Element
        this.card = this.elements.create('card', {style: style});

        // Add an instance of the card Element into the `card-element` <div>
        this.card.mount('#card-element');
    },

    submit: function() {
        var user = new UserModel({username: Snapstack.userInfo.username});
        console.log(user);

        var finalCallback = this.confirmCallback;

        stripe.createToken(this.card).then(function(result) {
            // handle result.error or result.token
            if(result.error !== undefined) {
                var options = {
                    type:'danger',
                    title: 'Error!',
                    message: 'There was an error registring the card, please try again later.'
                };

                Snapstack.vent.trigger('app:showAlert', options);
                return;
            }

            console.log(result.token);

            finalCallback.call();
        });
    },

    serializeData: function() {
        return {
            confirmLabel: this.confirmLabel
        };
    }
});

module.exports = StripeModalView;