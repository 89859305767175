var Backbone = require("backbone"),
    bbm = require("backbone.modal"),
    // slick = require('slick-carousel'),
    ProjectModel = require("../models/project"),
    // UserModel = require('../models/user'),
    template = require("../../dist/templates").projectModal;

var ProjectModalView = Backbone.Modal.extend({
    template: template,
    id: 'project-modal',
    cancelEl: ".dismiss",
    submitEl: "#confirm-btn",

    initialize: function(options) {
        // this.model = options.model || new ProjectModel();

        this.confirmLabel = options.confirmLabel;
        this.confirmCallback = options.confirmCallback;
        this.optional = options.optional;

        // console.log(this.model);
    },

    onShow: function() {
        //Add glass effect to background content
        $("#navbar-container").addClass('blur-effect');
        $("#content-container").addClass('blur-effect');

        if(!this.optional)
            $('.dismiss').remove();

        // this.scrollToTop(500);
        // window.scrollTo(0, 0);
        //Gotta cheat scroll cause window opens at bottom
        $('#project-modal').scroll();
        $("#project-modal").animate({
          scrollTop: 0
        }, 0000);

        $('.carousel-container').slick({
          prevArrow: '#modal-btn-carousel-back',
          nextArrow: '#modal-btn-carousel-next',
          arrows: true,
          speed: 500
        });

        $('.carousel-container').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            var nextEl = $("[data-slick-index='" + nextSlide + "']");
            var description = nextEl.attr("alt");
            $("#modal-carousel-img-text").html(description);
            // console.log(nextEl);
            // console.log(description);
        });

        // this.elements = stripe.elements();

        // Custom styling can be passed to options when creating an Element.
        // var style = {
        //   base: {
        //     color: '#303238',
        //     fontSize: '16px',
        //     lineHeight: '48px',
        //     fontSmoothing: 'antialiased',
        //     '::placeholder': {
        //       color: '#ccc',
        //     },
        //   },
        //   invalid: {
        //     color: '#e5424d',
        //     ':focus': {
        //       color: '#303238',
        //     },
        //   },
        // };

        // Create an instance of the card Element
        // this.card = this.elements.create('card', {style: style});

        // Add an instance of the card Element into the `card-element` <div>
        // this.card.mount('#card-element');
    },

    submit: function() {
        //Remove glass effect to background content
        $("#navbar-container").removeClass('blur-effect');
        $("#content-container").removeClass('blur-effect');
    },

    cancel: function() {
        //Remove glass effect to background content
        $("#navbar-container").removeClass('blur-effect');
        $("#content-container").removeClass('blur-effect');
    },

    scrollToTop: function(scrollDuration) {
        var cosParameter = window.scrollY / 2,
            scrollCount = 0,
            oldTimestamp = performance.now();
        function step (newTimestamp) {
            scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
            if (scrollCount >= Math.PI) window.scrollTo(0, 0);
            if (window.scrollY === 0) return;
            window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
            oldTimestamp = newTimestamp;
            window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
    },

    // serializeData: function() {
    //     return {
    //         project: this.serializeModel(),
    //         confirmLabel: this.confirmLabel
    //     };
    // }
});

module.exports = ProjectModalView;