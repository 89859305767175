JOHNSMITH_PRELOADIMAGES = [
	"assets/img/face1.png",
	"assets/img/face2.png",
	"assets/img/face3.png",
	"assets/img/face4.png",
	"assets/img/face5.png",
	"assets/img/face6.png",
	"assets/img/faces/1/back.png",
	"assets/img/faces/1/eyes.png",
	"assets/img/faces/1/mouth.png",
	"assets/img/faces/1/nose.png",
	"assets/img/faces/2/back.png",
	"assets/img/faces/2/eyes.png",
	"assets/img/faces/2/mouth.png",
	"assets/img/faces/2/nose.png",
	"assets/img/faces/3/back.png",
	"assets/img/faces/3/eyes.png",
	"assets/img/faces/3/mouth.png",
	"assets/img/faces/3/nose.png",
	"assets/img/faces/4/back.png",
	"assets/img/faces/4/eyes.png",
	"assets/img/faces/4/mouth.png",
	"assets/img/faces/4/nose.png",
	"assets/img/faces/5/back.png",
	"assets/img/faces/5/eyes.png",
	"assets/img/faces/5/mouth.png",
	"assets/img/faces/5/nose.png",
	"assets/img/faces/6/back.png",
	"assets/img/faces/6/eyes.png",
	"assets/img/faces/6/mouth.png",
	"assets/img/faces/6/nose.png",
	"assets/img/signatures/blanches/1.png",
	"assets/img/signatures/blanches/2.png",
	"assets/img/signatures/blanches/3.png",
	"assets/img/signatures/blanches/4.png",
	"assets/img/signatures/blanches/5.png",
	"assets/img/signatures/blanches/6.png",
	"assets/img/signatures/blanches/7.png",
	"assets/img/signatures/blanches/8.png",
	"assets/img/signatures/blanches/9.png",
	"assets/img/signatures/blanches/10.png",
	"assets/img/signatures/blanches/11.png",
	"assets/img/signatures/blanches/12.png",
	"assets/img/signatures/noires/1.png",
	"assets/img/signatures/noires/2.png",
	"assets/img/signatures/noires/3.png",
	"assets/img/signatures/noires/4.png",
	"assets/img/signatures/noires/5.png",
	"assets/img/signatures/noires/6.png",
	"assets/img/signatures/noires/7.png",
	"assets/img/signatures/noires/8.png",
	"assets/img/signatures/noires/9.png",
	"assets/img/signatures/noires/10.png",
	"assets/img/signatures/noires/11.png",
	"assets/img/signatures/noires/12.png",
];

module.exports = JOHNSMITH_PRELOADIMAGES;