var Marionette = require('backbone.marionette'),
    _ = require('underscore'),
    // $ = require('jquery'),
    // say = require('say'),
    Backbone = require('backbone'),
    ProjectsCollection = require('../collections/projects'),
    ProjectModalView = require('./projectModal'),
    SnapstackUrl = require('../config/snapstackUrl'),
    JohnSmithTaglines = require('../config/johnSmithTaglines'),
    JohnSmithTitles = require('../config/johnSmithTitles'),
    JohnSmithPreload = require('../config/johnSmithPreload'),
    template = require('../../dist/templates').home;

var HomeView = Marionette.View.extend({
    template: template,
    id: 'home-container',

    ui: {
        btnProject: ".btn-project",
        btnBackMainPage:"#btn-back-main-page"
    },

    events: {
        'click @ui.btnProject': 'handleClickProject',
        'click @ui.btnBackMainPage': 'handleClickBackMainPage'
    },

    // triggers: {
    //     'submit form': 'handleSubmitForm'
    // },

    initialize: function() {
        var me = this;

        this.projects = new ProjectsCollection();
        this.projects.fetch({
                    success: function (collection, response, options) {
                        // you can pass additional options to the event you trigger here as well
                        // self.trigger('successOnFetch');
                        var imageArr = new Array();
                        collection.forEach(function(project) {
                            var masterImage = project.get('imageUrl');
                            imageArr.push(masterImage);
                            var carousel = project.get('carousel');
                            carousel.forEach(function(image){
                                // console.log(image.imageUrl);
                                imageArr.push(image.imageUrl);
                            });

                        });

                        // console.log(imageArr);
                        var images = new Array();
                        function preload(imagesPre) {
                            for (i = 0; i < imagesPre.length; i++) {
                                images[i] = new Image()
                                images[i].src = imagesPre[i]
                            }
                        }
                        preload(imageArr);
                    },
                    // done: me.preloadPics()
        });

        // var images = new Array()
        // function preload(imagesPre) {
        //     for (i = 0; i < imagesPre.length; i++) {
        //         images[i] = new Image()
        //         images[i].src = imagesPre[i]
        //     }
        // }
        // preload(JohnSmithPreload);

        window.onresize = function() {
            me.scrollToHoriz(500, 'main-window');
        };
    },

    onRender: function() {

    },

    handleClickProject: function(el) {
        // var project = el.currentTarget.id;
        var projectId = parseInt(el.currentTarget.attributes['projectId'].value);
        var projectMod = this.projects.get(projectId);

        // this.showChildView('projectInfo', new ProjectInfoView({
        //     model: projectMod
        // }));

        // console.log(el.currentTarget.attributes['projectId'].value);

        //Verify if user has a subscription
        var options = {

            confirmLabel: "Save",
            model: projectMod,
            // confirmCallback: _.bind(this.chargeCard, this), //Function to sub
            optional: true
        };
        //If user has a subscription, create a new subscription model
        

        Snapstack.showChildView('modalContainer', new ProjectModalView(options));
        // console.log(el.currentTarget.id);
    },

    handleClickBackMainPage: function() {
        this.speechVolume = 1;
        this.screenActive = 0;
        this.scrollToHoriz(500, 'main-window');
    },

    scrollToHoriz: function(scrollDuration, id) {
        var elPos = this.getPosition(document.getElementById(id));
        var pagePos = { x: document.documentElement.scrollLeft, y : document.documentElement.scrollTop };
        var directions = { x : elPos.x > pagePos.x, y : elPos.y > pagePos.y};

        var xParameter = (Math.abs(elPos.x) / 2),
            yParameter = (Math.abs(elPos.y) / 2),

            scrollCount = 0,
            oldTimestamp = performance.now(),
            firstTimestamp = oldTimestamp;
        function step (newTimestamp) {
            scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
            // console.log(scrollCount)
            if (scrollCount >= Math.PI) window.scrollTo(elPos.x, elPos.y);

            var newScrolls = { x : 0, y : 0};

            if (directions.x) {
                newScrolls.x = xParameter - xParameter * Math.cos(scrollCount);
            } else {
                newScrolls.x = xParameter + xParameter * Math.cos(scrollCount);
            }

            if (directions.y) {
                newScrolls.y = yParameter - yParameter * Math.cos(scrollCount);
            } else {
                newScrolls.y = yParameter + yParameter * Math.cos(scrollCount);
            }

            window.scrollTo(Math.round(newScrolls.x), 0);
            oldTimestamp = newTimestamp;

            if(newTimestamp >= (firstTimestamp + scrollDuration)){
                if (directions.x) {
                    newScrolls.x = xParameter - xParameter * Math.cos(Math.PI);
                } else {
                    newScrolls.x = xParameter + xParameter * Math.cos(Math.PI);
                }

                if (directions.y) {
                    newScrolls.y = yParameter - yParameter * Math.cos(Math.PI);
                } else {
                    newScrolls.y = yParameter + yParameter * Math.cos(Math.PI);
                }

                window.scrollTo(newScrolls.x, 0);
                return;
            }
            window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
    },

    scrollTo: function(scrollDuration, id) {
        var elPos = this.getPosition(document.getElementById(id));
        var pagePos = { x: document.documentElement.scrollLeft, y : document.documentElement.scrollTop };
        var directions = { x : elPos.x > pagePos.x, y : elPos.y > pagePos.y};

        var xParameter = Math.abs(elPos.x) / 2,
            yParameter = Math.abs(elPos.y) / 2,

            scrollCount = 0,
            oldTimestamp = performance.now(),
            firstTimestamp = oldTimestamp;
        function step (newTimestamp) {
            scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
            // console.log(scrollCount)
            if (scrollCount >= Math.PI) window.scrollTo(elPos.x, elPos.y);

            var newScrolls = { x : 0, y : 0};

            if (directions.x) {
                newScrolls.x = xParameter - xParameter * Math.cos(scrollCount);
            } else {
                newScrolls.x = xParameter + xParameter * Math.cos(scrollCount);
            }

            if (directions.y) {
                newScrolls.y = yParameter - yParameter * Math.cos(scrollCount);
            } else {
                newScrolls.y = yParameter + yParameter * Math.cos(scrollCount);
            }

            window.scrollTo(Math.round(newScrolls.x), Math.round(newScrolls.y));
            oldTimestamp = newTimestamp;

            if(newTimestamp >= (firstTimestamp + scrollDuration)){
                if (directions.x) {
                    newScrolls.x = xParameter - xParameter * Math.cos(Math.PI);
                } else {
                    newScrolls.x = xParameter + xParameter * Math.cos(Math.PI);
                }

                if (directions.y) {
                    newScrolls.y = yParameter - yParameter * Math.cos(Math.PI);
                } else {
                    newScrolls.y = yParameter + yParameter * Math.cos(Math.PI);
                }

                window.scrollTo(newScrolls.x, newScrolls.y);
                return;
            }
            window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
    },

    scrollToTop: function(scrollDuration) {
        var cosParameter = window.scrollY / 2,
            scrollCount = 0,
            oldTimestamp = performance.now();
        function step (newTimestamp) {
            scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
            if (scrollCount >= Math.PI) window.scrollTo(0, 0);
            if (window.scrollY === 0) return;
            window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
            oldTimestamp = newTimestamp;
            window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
    },

    getPosition: function(el) {
        var xPos = 0;
        var yPos = 0;

        while (el) {
            if (el.tagName == "BODY") {
                // deal with browser quirks with body/window/document and page scroll
                var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
                var yScroll = el.scrollTop || document.documentElement.scrollTop;

                xPos += (el.offsetLeft - xScroll + el.clientLeft);
                yPos += (el.offsetTop - yScroll + el.clientTop);
            } else {
                // for all other non-BODY elements
                xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
                yPos += (el.offsetTop - el.scrollTop + el.clientTop);
            }

            el = el.offsetParent;
        }
        return {
            x: xPos,
            y: yPos
        };
    }
});

module.exports = HomeView;
