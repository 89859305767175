var Marionette = require('backbone.marionette');

var FadeInOutRegion = Marionette.Region.extend({
  attachHtml: function(view){
    // Some effect to show the view:
    this.$el.empty().append(view.el);
    this.$el.hide().fadeIn(350);
  },

  removeView(view) {
    var self = this;
    view.$el.fadeOut(350, function() {
      self.destroyView(view);
      if (self.currentView) self.currentView.$el.fadeIn('slow');
    });
  }
});

module.exports = FadeInOutRegion;